import { useState, useEffect } from "react";
import { getDocuments } from "lib/middlewares/documents";

export interface IDocument {
    [key: string]: {
        url: string;
        name: string;
    }
}

export interface IDocumentItem {
    key: string;
    document: IDocument;
}

export const useGetDocuments = () => {
    const [ documents, setDocuments ] = useState<IDocumentItem[]>([]);

    const defaultDoc: IDocumentItem = {
        key: "default",
        document: {
            default: {
                url: "",
                name: "",
            },
        },
    };

    const docUrl = process.env.STRAPI_API?.replace("/api", "");

    useEffect(() => {
        void getDocuments().then((data) => {
            if (data.length) {
                const list = data.map((item) => {
                    const defaulrUrl: string = item.attributes.documentFile.data.attributes.url;
                    const documentList: IDocumentItem = {
                        key: item.attributes.title.replace(/ /g, ""),
                        document: {
                            default: {
                                url: defaulrUrl,
                                name: item.attributes.title,
                            },
                        },
                    };
                    if(item.attributes.localizations.data.length) {
                        item.attributes.localizations.data.map((value) => {
                            documentList.document[value.attributes.locale] = {
                                url: value.attributes.documentFile?.data.attributes.url || defaulrUrl,
                                name: value.attributes.title,
                            };
                        });
                    }
                    return documentList;
                });
                setDocuments(list);
            }
        });
    }, []);

    return { documents, defaultDoc, docUrl };
};
