import React from "react";
import { useTranslation } from "next-i18next";
import { StyledContainer } from "styles/basic.styled";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { useGetDocuments } from "lib/hooks/use-get-documents/use-get-documents";
import { useLanguage } from "components/providers/language/language.provider";
import { StyledFooter, StyledFooterText, StyledDocLInk } from "./auth-footer.styled";

export const AuthFooter: React.FC = () => {
    const [__] = useTranslation();
    const { currentLocale } = useLanguage();
    const { documents, defaultDoc, docUrl } = useGetDocuments();

    const [footerText, textDocuments, footerText2] = __("auth.risk-warning").split("{sep}");

    const documentRiskdisclosure = documents.find((i) => i.key === "Riskdisclosure") || defaultDoc;

    return (
        <StyledFooter>
            <StyledContainer>
                <StyledFooterText variant={TypographyTypes.SMALL}>
                    {footerText}
                    <StyledDocLInk href={`${docUrl}${documentRiskdisclosure.document[currentLocale]?.url || documentRiskdisclosure.document.default.url}`} target="_blank">{textDocuments}</StyledDocLInk>
                    {footerText2}
                </StyledFooterText>
            </StyledContainer>
        </StyledFooter>
    );
};
